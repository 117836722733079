/* Jeopardye logo font face */
@font-face {
    font-family: "Gyparody";
    src: url("../../fonts/gyparody.woff");
}

/* Clue value font face */
@font-face {
    font-family: "Swiss 911";
    src: url("../../fonts/Swiss_911_Compressed.ttf");
}

@font-face {
    font-family: "Swiss 911 Extra";
    src: url("../../fonts/Swiss_911_Extra_Compressed.woff");
}

/* Fonts for displaying clues
 * Korinna is what is used on Jeopardy, and Newsreader is a fallback from Google Fonts. */
@font-face {
    font-family: "Korinna";
    src: url("../../fonts/Korinna-Regular.otf");
}

@font-face {
    font-family: "Korinna Bold";
    src: url("../../fonts/Korinna_Bold_BT.ttf");
}

@font-face {
    font-family: "Newsreader";
    src: url("../../fonts/Newsreader-VariableFont_opsz,wght.ttf");
}

/* Fonts for displaying player names */
@font-face {
    font-family: "Beth Ellen";
    src: url("../../fonts/BethEllen-Regular.ttf");
}

@font-face {
    font-family: "Caveat Brush";
    src: url("../../fonts/CaveatBrush-Regular.ttf");
}

@font-face {
    font-family: "Dancing Script";
    src: url("../../fonts/DancingScript-VariableFont_wght.ttf");
}

@font-face {
    font-family: "Gloria Hallelujah";
    src: url("../../fonts/GloriaHallelujah-Regular.ttf");
}

@font-face {
    font-family: "Homemade Apple";
    src: url("../../fonts/HomemadeApple-Regular.ttf");
}

@font-face {
    font-family: "Kaushan Script";
    src: url("../../fonts/KaushanScript-Regular.ttf");
}

@font-face {
    font-family: "Rancho";
    src: url("../../fonts/Rancho-Regular.ttf");
}

@font-face {
    font-family: "Rock Salt";
    src: url("../../fonts/RockSalt-Regular.ttf");
}

@font-face {
    font-family: "Rouge Script";
    src: url("../../fonts/RougeScript-Regular.ttf");
}

@font-face {
    font-family: "Sacramento";
    src: url("../../fonts/Sacramento-Regular.ttf");
}

@font-face {
    font-family: "Satisfy";
    src: url("../../fonts/Satisfy-Regular.ttf");
}

@font-face {
    font-family: "Shadows Into Light";
    src: url("../../fonts/ShadowsIntoLight-Regular.ttf");
}

/* Miscellaneous font faces */
@font-face {
    font-family: "Square 721";
    src: url("../../fonts/Square_721_Bold_BT.woff");
}

@keyframes pulsating-background {
    from {
        background: #1D08A3;
    }
    to {
        background: #8D2AB5;
    }
}

html, body {
    height: 100%;
}

#root {
    background-image: linear-gradient(to bottom right, #FFCC00, #1D08A3);
    height: 100%;
}

.hover-not-allowed:hover {
    cursor: not-allowed;
}

.hover-pointer:hover {
    cursor: pointer;
}

.hover-blue:hover {
    color: #1D08A3;
}

.hover-yellow:hover {
    color: #FFCC00;
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0.5rem 1rem;
    text-decoration: none;
    border: 1px solid rgba(0, 0, 0, 0.125);
    user-select: none;
}

.list-group-menu-item:hover {
    background: var(--chakra-colors-jeopardyeBlue-500);
    color: white;
}

.player-list {
    color: black;
    min-width: 150px;
}

.popover-card {
    color: black;
    min-width: 230px;
}

.card-header {
    background-color: #1D08A3;
    color: white;
    cursor: default;
}

.empty-list, .menu-item-disabled {
    color: #555555;
}

.menu-item-disabled {
    cursor: default;
}

.btn-check {
    position: absolute;
    clip: rect(0, 0, 0, 0);
}

.btn-check:hover {
    cursor: pointer;
}

.radio-toggle-button {
    margin: 0 20px;
}

.toggle-switch {
    padding-left: 3.5em;
}

.toggle-switch-disabled {
    color: #888888;
}

.logo-page {
    color: black;
}

.logo-page-content {
    margin-top: 150px;
    width: 100%;
}

.logo {
    color: #1D08A3;
    cursor: default;
    font-family: "Gyparody", sans-serif !important;
    font-weight: bolder !important;
    font-size: 120px !important;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

button[role=tab]:focus {
    box-shadow: none;
    outline: none;
}

button[role=tab][aria-selected=true] {
    color: #1D08A3;
    font-weight: bold;
}

.animate-pulsating-background {
    animation-name: pulsating-background;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}
