.room-code {
    font-size: 40px;
    font-weight: bolder;
}

.game-settings {
    border: 4px solid #1D08A3 !important;
}

.game-starting {
    padding: 100px 30px;
}
