body {
    font: 24px Helvetica, sans-serif;
}

.bg-purple {
    background-color: #8D2AB5;
}

.game {
    color: white;
    height: 550px;
    min-height: 550px;
    min-width: 1200px;
}

.category-heading {
    background-color: #1D08A3;
    color: white;
    cursor: default;
    font-family: Helvetica, sans-serif;
    font-weight: bold;
    min-height: 90px;
    min-width: 200px;
    text-align: center;
    text-shadow: 3px 3px 1px black;
    text-transform: uppercase;
}

.small-heading {
    font-size: 20px;
}

.clue {
    background-color: #1D08A3;
    color: #FFCC00;
    cursor: default;
    font-family: "Swiss 911", Helvetica, sans-serif;
    font-size: 48px;
    min-width: 200px;
    padding: 0;
    text-align: center;
    text-shadow: 5px 5px black;
}

.clue-border {
    border: 10px solid #1D08A3;
}

.clue-border.selected-clue,
.clue-border.selectable-clue:hover {
    border: 10px solid #FFCC00;
}

.active-clue-container {
    background-color: #1D08A3;
    cursor: default;
    position: absolute;
    top: 0;
    left: 0;
    vertical-align: center;
    z-index: 100;
}

.active-clue {
    font: 70px "Korinna", "Korinna Bold", "Newsreader", "Times", serif;
    font-weight: bold;
    text-align: center;
    text-shadow: 5px 5px 2px black;
    text-transform: uppercase;
    word-spacing: 0.2em;
}

.active-clue-sm {
    font-size: 60px !important;
}

.active-clue-xs {
    font-size: 50px !important;
}

.active-clue-buttons {
    bottom: 0 !important;
    right: 0 !important;
}

.active-clue-button {
    margin: 5px;
}

.selectable-clue-button:hover {
    color: #FFCC00;
}

.countdown-timer {
    height: 30px;
    border: 2px solid white;
}

.podium-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 200;
}

.podium {
    border: 5px solid white;
    border-radius: 1.25rem;
    cursor: default;
}

.podium-lg {
    min-width: 300px;
}

.podium-md {
    min-width: 250px;
}

.podium-sm {
    min-width: 200px;
}

.podium-xs {
    min-width: 180px;
}

.podium-center {
    background-color: #1D08A3;
    font-weight: bold;
    min-height: 50px;
    width: 70%;
}

.podium-center-xs {
    width: 90% !important;
}

.podium-side {
    background-color: #8A482D;
    min-height: 50px;
    padding: 0;
    width: 15%;
}

.podium-left-side {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.podium-right-side {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.podium-stripe {
    background-color: white;
    margin-top: 18px;
    margin-left: 3px;
    margin-right: 3px;
    height: 5%;
}

.podium-score {
    border-bottom: 4px solid black;
}

.podium-score-negative {
    color: red;
}

.podium-indicator {
    min-height: 15px;
}

.podium-indicator-active {
    background-color: white;
}

.podium-name-xl {
    font-size: 36px;
}

.podium-name-lg {
    font-size: 30px;
}

.podium-name-md {
    font-size: 24px;
}

.podium-name-sm {
    font-size: 20px;
}

.podium-name-xs {
    font-size: 18px;
}

.podium-name-active {
    background-color: white;
    color: #1D08A3;
}

.status-text {
    cursor: default;
}

.chakra-alert {
    font-size: 25px !important;
}
